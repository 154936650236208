<script lang="ts" setup></script>

<template>
  <main class="min-h-screen max-w-full flex flex-col">
    <slot />
    <div
      class="fixed bottom-0 right-0 px-1 bg-gray-500/50 text-white text-[12px]"
    >
      v{{ useRuntimeConfig().public.version }}
    </div>
  </main>
</template>
